<template>
	<div class="search-a">
		<!-- 顶部搜索跳转此页面 -->
		<div class="search-b">
			<!-- 头部搜索框 -->
			<div class="search-c">
				<el-input v-model="searhKey" placeholder="请输入您想搜索的内容" @keydown.enter.native="bindSearch"></el-input>
			</div>
			<div class="search-d" @click="bindSearch" >搜索</div>
		</div>

		<div class="search-e">
			<div class="search-f">
				<!-- 搜索框下方 筛选 -->
				<!-- <div class="search-g" :class="searchg">
					
					<div class="search-h">筛选:</div>
					<div class="search-r">
						<div class="search-i">
							<div class="search-j" v-for="(item,index) in shaixuan" :key="index">
								<div class="" :class="[shaixuana==index?'search-k':'search-l']" @click="btn(index)">
									{{item.shaia}}</div>
								<div class="search-m">{{item.shaib}}</div>
							</div>
						</div>
					</div>
				</div> -->

				<div class="search-n">
					<!-- 搜索框下方 搜索后显示内容 -->
					<div class="">以下搜索结果为内容中含 “{{searhResultKey}}” 的全部内容, </div>
					<div class="">一共找到{{searchResultCount}}条结果</div>
				</div>

				<div class="">
					<!-- 全部内容开头 -->
					<div class="">
						<div class="search-o" v-for="(item,index) in searchResults" :key="index">
							<!-- 全部内容攻略 游记 优惠资讯 开头-->
							<!-- <div class="search-p">
								<div class="search-q">
									<div class="search-s">游记</div>
									<div class="search-t">{{item.seacrhb}}</div>
								</div>
								<div class="search-u">查看更多结果</div>
							</div> -->

							<div class="search-v">
								<div class="search-img">
									<a href="javascipt:;"  @click="bindJumpDetail(item)">
										<img :src="item.CoverImagePath"
											class="search-imga">
									</a>
								</div>
								<div class="search-w" @click="bindJumpDetail(item)">
									<div class="search-xy">
										<div class="search-x">
											<a href="javascipt:;">{{item.Title}}</a>
										</div>
										<div class="search-y">
											<a href="javascipt:;">
												{{item.Summarize}}
											</a>
										</div>
									</div>

									<div class="search-z">
										<div class="">
											<a href="" class="search-name">
												<el-avatar :src="item.AuthorFacePath"></el-avatar>
												<div class="search-namea">{{item.AuthorName}}</div>
											</a>
										</div>
										<div class="search-tou">
											<div class="search-toua">{{item.TimeEXSpan}}</div>
											<div class="search-tou">
												<div class="search-toub"><img src="../../assets/liulan.png"
														class="search-touc"></div>
												<div class="search-toua">{{item.ViewCount}}</div>
											</div>
											<div class="search-tou">
												<div class="search-toub"><img src="../../assets/dianzan.png"
														class="search-touc"></div>
												<div class="search-toua">{{item.PlacetCount}}</div>
											</div>
											<div class="search-tou">
												<div class="search-toub"><img src="../../assets/xingxin.png"
														class="search-touc"></div>
												<div class="search-toua">{{item.CommentCount}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 全部内容攻略 游记 优惠资讯 结尾-->
						</div>
						<!-- 全部内容攻略 游记 优惠资讯 下方问答:(64) 开头-->
						<div class="search-o" v-show="false">
							
							<div class="search-p">
								<div class="search-q">
									<div class="search-s">问答:</div>
									<div class="search-t">(64)</div>
								</div>
								<div class="search-u">查看更多结果</div>
							</div>

							<div class="">
								<div class="search-wdn" v-for="item in [1,2,3]" :key="item">
									<div class="">
										<p class="search-wdo">
											<a href="" class="search-wdoo">你好，我查了一下上海到大阪的特价机票，后面写着需候补，是什么意思？请帮我解答一下，谢谢
											</a>
										</p>
										<div class="search-wdp">发表于2014年11月12日 10:50</div>
										<div class="search-wdq">国际机票提前多长时间订最好</div>
										<div class="search-wdq">{{item+123456789}}</div>
									</div>
									<div class="search-wdr">
										<a href="" class="search-wds">
											<span>{{item*92}}</span>
											<span>回答</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<!-- 全部内容最下方旅行顾问 -->
					<div class="search-o" v-show="false">
						
						<div class="search-p">
							<div class="search-q">
								<div class="search-s">旅行顾问:</div>
								<div class="search-t">(5)</div>
							</div>
							<div class="search-u">查看更多结果</div>
						</div>
						<div class="search-kefuz">
							<div class="search-kefu" v-for="item in [1,2,3,4,5]" :key="item">
								<div class="search-kefua">
									<div class="search-kefub">
										<!-- 全部内容客服 -->
										<a href="">
											<el-avatar :size="60"
												src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
											</el-avatar>
										</a>
										<div class="search-kefuc">
											<div class="search-kefue">
												<a href="">王客服</a>
											</div>
											<div class="search-kefud">
												<img src="../../assets/guanzhu.png" class="search-kefuf">
												<a href="">关注Ta</a>
											</div>
											<!-- <div class="search-guanz">
												<div class="search-guanza">
													<div class="search-kefud">
														<img src="../../assets/dagou.png" class="search-kefuf">
														<a href="">已关注</a>
													</div>
												</div>
												<div class="search-guanzb">
													<div class="search-kefud">
														<img src="../../assets/shanchuc.jpg" class="search-kefuf">
														<a href="">取关Ta</a>
													</div>
												</div>
											</div> -->
										</div>
									</div>
									<div class="search-kefug">
										<!-- 全部内容分页 -->
										<div class="search-kefuh">个人说明:</div>
										<div class="search-kefui">
											<a href="" title="真诚服务，伴你同行">真诚服务，伴你同行!!</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 全部内容结尾 -->
				</div>

				<div class="" v-show="false">
					<!-- 旅行顾问开头 -->
					<div class="search-o">
						<div class="search-p">
							<div class="search-q">
								<div class="search-s">旅行顾问:</div>
								<div class="search-t">(5)</div>
							</div>
							<div class="search-u">查看更多结果</div>
						</div>
						<div class="search-kefuzz">
							<div class="search-kefu" v-for="item in [1,2,3,4,5,6]" :key="item">
								<div class="search-kefua">
									<!-- 旅行顾问客服 -->
									<div class="search-kefub">
										<a href="">
											<el-avatar :size="60"
												src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
											</el-avatar>
										</a>
										<div class="search-kefuc">
											<div class="search-kefue">
												<a href="">王客服</a>
											</div>
											<div class="search-kefud">
												<img src="../../assets/guanzhu.png" class="search-kefuf">
												<a href="">关注Ta</a>
											</div>
											<!-- <div class="search-guanz">
												<div class="search-guanza">
													<div class="search-kefud">
														<img src="../../assets/dagou.png" class="search-kefuf">
														<a href="">已关注</a>
													</div>
												</div>
												<div class="search-guanzb">
													<div class="search-kefud">
														<img src="../../assets/shanchuc.jpg" class="search-kefuf">
														<a href="">取关Ta</a>
													</div>
												</div>
											</div> -->
										</div>
									</div>
									<div class="search-kefug">
										<div class="search-kefuh">个人说明:</div>
										<div class="search-kefui">
											<a href="" title="真诚服务，伴你同行">真诚服务，伴你同行!!</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="search-fenye">
						<!-- 旅行顾问分页 -->
						<el-pagination background layout="prev, pager, next" :total="100"></el-pagination>
					</div>
					<!-- 旅行顾问结尾 -->
				</div>
				<div class="list-fbt" v-if="searchResults!=null&&searchResults.length>0">
					<!-- 全部游记下方 选项卡 最新发表 热门游记 精品游记  分页-->
					<el-pagination  background layout="prev, pager, next"
						:total="searchResultCount"  :page-size="PageSize" :current-page='PageIndex'
						@current-change='handleCurrentChange'>
					</el-pagination>
				</div>
			</div>

			<div class="">
				<div class="search-mudia">
					<!-- 右边目的地旅行顾问 -->
					<div class="search-guwen">目的地旅行顾问</div>
					<div class="search-guwena" v-if="StaffContacts!=null">
						<marquee id="marquee" scrollAmount=2 scrollDelay=0 direction=up behavior="scroll"
							style="height: 100%;" onmouseover="this.stop()" onmouseout="this.start()">
							<!-- 右边目的地旅行顾问走马灯 -->
							<div class="" v-for="(item,index) in StaffContacts" :key="index" @mouseover="marqueeMove()"
								@mouseleave="marqueeOut()">
								<el-tooltip placement="top" effect="light" popper-class="lx">
									<div slot="content" @mouseover="marqueeMove()" @mouseleave="marqueeOut()">
										<div class="search-lxguwena">
											<div class="search-lxguwen">
												<el-avatar :size="90"
													:src="item.UserImg">
												</el-avatar>
												<div class="search-lxguwenb">
													<div class="search-lxguwenc">
														<div class="search-lxguwenh">
															<a href="javascript:;">{{item.StaffName}}</a>
														</div>
														<div class="search-lxguwend">
															<div class="search-lxguwendm">
																<img src="../../assets/nvxing.png"
																	class="search-lxguwendg">
																<!-- <img src="../../assets/nanxing.png" class="search-lxguwendg"> -->
															</div>
															<div class="search-lxguwenf" title="一起飞®国际旅行顾问">
																一起飞®国际旅行顾问</div>
														</div>
													</div>
													<div class="search-lxguwene">
														<div class="search-lxguweni">精通领域:</div>
														<div class="search-lxguwenz">{{item.JingTong}}</div>
													</div>
												</div>
											</div>

											<div class="search-lxdh">
												<div class="">
													<div class="search-lxdha">
														<div class="">手机号:</div>
														<div class="search-lxdhc">{{item.Phone}}</div>
													</div>
													<div class="search-lxdha">
														<div class="">电子邮箱:</div>
														<div class="search-lxdhc">{{item.Email}}</div>
													</div>
												</div>
												<div class="search-lxdhd">
													<img :src="item.QrcodeImg"
														class="search-lxdhe">
												</div>
											</div>

											<div class="search-lxdhf">
												<div class="search-lxdhg">
													<a href="">在线向TA提问</a>
												</div>
												<div class="search-lxdhh">
													<!-- <a href="" class="search-lxdhi search-lxdhh">
														<img src="../../assets/jiahao.png" class="search-guan">
														<div class="">加关注</div>
													</a> -->
													<!-- <div class="search-guanz">
														<div class="search-guanza">
															<a href="" class="search-lxdhh search-lxdhi">
																<img src="../../assets/dagou.png" class="search-guan">
																<div class="">已关注</div>
															</a>
														</div>
														<div class="search-guanzb">
															<a href="" class="search-lxdhh search-lxdhi">
																<img src="../../assets/shanchub.png" class="search-guan">
																<div class="">取消关注</div>
															</a>
														</div>
													</div> -->
													<!-- <div class="search-lxdho search-lxdhh">
														<img src="../../assets/lianxi.png" class="search-lx">
														<div class="">QQ</div>
														<div class="">咨询</div>
													</div> -->
												</div>
											</div>
										</div>
									</div>
									<div>
										<div class="search-guwenl">
											<div class="search-guwenb">
												<div class="search-guwenc">
													<el-avatar size="large"
														:src="item.UserImg">
													</el-avatar>
												</div>
												<div class="search-guwend">
													<div class="search-guwene">
														<div class="search-guwenf search-guwenz">
															{{item.StaffName}}&nbsp;&nbsp;</div>
														<div class="search-guweng"><img
																src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
																class="search-guwenh" title="V5旅游顾问"></div>
													</div>
													<div class="search-guwene">
														<div class="search-guwenff">精通领域:&nbsp;</div>
														<div class="search-guweni">{{item.JingTong}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-tooltip>
							</div>
							<!-- 右边目的地旅行顾问走马灯 -->
						</marquee>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	//接口	
	var wqgApi = require('@/api/wqgApi.js');
	//组件引用

	//公共属性
	var that;

	export default {
		components: {
			//组件

		},
		data() {
			return {
				id: '',
				title: "社区首页",
				input: '',
				shaixuana: 0,
				// shaixuan: [{
				// 		shaia: '全部内容',
				// 		shaib: '|'
				// 	},
				// 	{
				// 		shaia: '旅行顾问',
				// 		shaib: '|'
				// 	},
				// 	{
				// 		shaia: '优惠资讯',
				// 		shaib: '|'
				// 	},
				// 	{
				// 		shaia: '游记',
				// 		shaib: '|'
				// 	},
				// 	{
				// 		shaia: '攻略',
				// 		shaib: '|'
				// 	},
				// 	{
				// 		shaia: '问答'
				// 	}
				// ],
				// seacrhl: [{
				// 	{seacrha: '攻略',
				// 		seacrhb: '（36）'
				// 	},
				// 	{
				// 		seacrha: '游记',
				// 		seacrhb: '（319）'
				// 	},
				// 	{
				// 		seacrha: '优惠资讯',
				// 		seacrhb: '（80434）'
				// 	}
				// ],
				searchg: '',
				searhKey: '',
				searhResultKey:'',
				PageSize:20,
				PageIndex:1,
				searchResults:null,
				searchResultCount:0,
				StaffContacts:null,//旅行顾问
			};
		},
		created() {
			that = this;
			var options = that.$route.query;
			console.log(options);
			if (!yqfCommon.isNullOrEmpty(options.searhKey)) {
				that.searhKey = options.searhKey;
				GetArticleListByUserCode();
			}
			GetOnlineStaffProfiles();
		},
		methods: {
			bindSearch:function(e){
				that.PageIndex=1;
				GetArticleListByUserCode();
			},			
			//分页
			handleCurrentChange(page) {
				console.log(`当前页: ${page}`);
				that.PageIndex = page;
				GetArticleListByUserCode();
				$(window).scrollTop("0");
			},
			bindJumpDetail:function(e){
				let path = '/Youji/article?id=' + e.ID;
				console.log(path);
				// window.location.href=path;
				that.$router.push({
					path: path
				});
			},
			btn(index) {
				this.shaixuana = index
				console.log(index)
			},
			marqueeMove: function() {
				// 右边目的地旅行顾问走马灯事件
				var marquee = document.getElementById('marquee');
				marquee.stop();
				// console.log('移入拉')
			},
			marqueeOut: function() {
				// 右边目的地旅行顾问走马灯事件
				var marquee = document.getElementById('marquee');
				marquee.start();
				// console.log('移出拉')
			}
		}
	};
	//获取相关的游记
	function GetArticleListByUserCode() {
		that.searhResultKey='';
		var parm = {
			PageSize: that.PageSize, //总页数
			OrderBy: 1, //排序方式(1、创建时间 2、浏览量 3、评论数 4、点赞数 99、无序 默认为按创建时间)
			PageIndex: that.PageIndex,			
			Title:that.searhKey
		};
		wqgApi.GetArticleListByUserCode(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.searchResults = result.data.ArticleList;
				that.searchResultCount=result.data.RowCount;
				that.searhResultKey=that.searhKey;
			} else {
	
			}
		});
	}
	//获取旅行顾问
	function GetOnlineStaffProfiles() {
		var parm = {
			id: ''
		};
		wqgApi.GetOnlineStaffProfiles(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.StaffContacts = result.data.StaffContacts
				// console.log(result.data.StaffContacts)
			} else {
	
			}
		})
	}
	window.onscroll = function() {
		// 筛选下来后定位到顶部
		//scrollTop是浏览器滚动条的top位置，
		var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
		//通过判断滚动条的top位置与可视网页之和与整个网页的高度来返回各项参数
		// console.log(scrollTop)
		if (scrollTop >= 307) {
			that.searchg = 'search-gg'
		} else {
			that.searchg = ''
		}
	}
</script>

<style lang="scss">
	@import '../../style/youji/search.scss';
</style>
